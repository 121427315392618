import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import { Pane, Table, Select } from "evergreen-ui";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';

const baseUrl = "/admin/bike";

function Main() {

  // const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState();
  const [locations, setLocations] = useState([]);
  const [bikeActs, setBikeActs] = useState([]);
  const [userActs, setUserActs] = useState([]);
  const [allBikeActs, setAllBikeActs] = useState([]);

  const [userActChartData, setUserActChartData] = useState([]);
  const [bikeActChartData, setBikeActChartData] = useState([]);
  const [chartData, setChartData] = useState([]);

  const ALL_CONTENT_PAGE_SIZE = 3;

  
  // const data = [{name: 'Page A', uv: 400, pv: 2400, amt: 2400}, {name: 'Page B', uv: 400, pv: 2400, amt: 2400}];

  const getAllUserActs = async () => {
    const params = {
      limit: ALL_CONTENT_PAGE_SIZE,
      offset: 0,
    };
    try {
      const response = await axios.get("/admin/user/acts", { params });
      setUserActs(response.data.user_act_list);
    
      const cd = response.data.user_act_list.map(v => {
        return {
          name: v.date,
          user_act_cnt: v.activity_count,
          user_cnt: v.user_count,
        }
      })
      setUserActChartData(cd);
    } catch(error) {
      alert(error.response.data.message);
    }
  }

  const getAllBikeActs = async () => {
    const params = {
      limit: ALL_CONTENT_PAGE_SIZE,
      offset: 0,
    };
    try {
      const response = await axios.get(baseUrl + "/locations/acts", { params });
      setAllBikeActs(response.data.act_list);
      const cd = response.data.act_list.map(v => {
        return {
          name: v.date,
          bike_act_cnt: v.activity_count,
          bike_cnt: v.bike_count,
        }
      })
      setBikeActChartData(cd);

    } catch (error) {
      alert(error.response.data.message);
    }
  }

  const getBikeLocations = async (type) => {
    try {
      let queryParam = "";
      if (type) {
        queryParam = `?location_type=${type}`
      } else {
        // 협찬개인 & 협찬시설 노출되도록 처리
        queryParam = `?group_codes=30,31`
      }
      const response = await axios.get(baseUrl + "/locations" + queryParam);

      let locations = response.data.location_list.map((v, i) => {
        v.index = i;
        return v;
      });
      // console.log("locations: ", locations);
      setLocations(locations);
    } catch(error) {
      alert(error.response.data.message);
    }
  }

  const getBikeActs = async (location) => {
    try{
      const response = await axios.get(baseUrl + `/locations/${location}/acts`)
      // console.log("> act response: ", response.data.act_list);
      setBikeActs(response.data.act_list);

    } catch(error) {
      alert(error.response.data.message);
    }
  }

  useEffect(() => {
    getAllUserActs();
    getAllBikeActs();
    // getBikeLocations("FACILITY");
    getBikeLocations();
  }, []);

  useEffect(() => {
    const mergedData = bikeActChartData.map((bikeData, idx) => {
      const [find] = userActChartData.filter(
        (userData) => userData.name === bikeData.name
      );
      if (find) {
        return Object.assign({}, bikeData, find);
      }
    });
    setChartData(mergedData.reverse());
  }, [userActChartData, bikeActChartData])

  // useEffect(() => {
  //   if(locations.length > 0) {
  //     getBikeActs(locations[selectedIndex].location);
  //   }
  // }, [selectedIndex, locations])

  useEffect(() => {
    if(locations.length > 0) {
      const selected = locations.find(data => data.location === selectedLocation)
      if (selected) {
        getBikeActs(selected.location);
      }
    }
  }, [selectedLocation, locations])
  

  return (
    <MainBox>
      <>
        <MainH1>Basic Dashboard</MainH1>

        <LineChart
          width={600}
          height={200}
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line name="바이크 기준 운동 횟수" type="monotone" dataKey="bike_act_cnt" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line name="계정 기준 운동 횟수" type="monotone" dataKey="user_act_cnt" stroke="#82ca9d" />
        </LineChart>

        <LineChart
          width={600}
          height={200}
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line name="활동 바이크 수" type="monotone" dataKey="bike_cnt" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line name="활동 계정 수" type="monotone" dataKey="user_cnt" stroke="#82ca9d" />
        </LineChart>


        <Pane width="100%" paddingLeft={40} paddingRight={40} paddingBottom={20}>
          <Pane width="48%" float="left">
            <Pane textAlign="center">
              <MainH2>회원(계정) 기준 활동 데이터</MainH2>
            </Pane>
            <Table textAlign="center">
              <Table.Head>
                <Table.TextCell>날짜</Table.TextCell>
                <Table.TextCell>운동 진행 횟수</Table.TextCell>
                <Table.TextCell>활동 계정 수</Table.TextCell>
              </Table.Head>
              <Table.Body >
                {userActs.map((data, index) => (
                  <Table.Row key={index}>
                    <Table.TextCell>{data.date}</Table.TextCell>
                    <Table.TextCell>{data.activity_count}</Table.TextCell>
                    <Table.TextCell>{data.user_count}</Table.TextCell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Pane>

          <Pane width="48%" float="right">
            <Pane textAlign="center">
              <MainH2>바이크 기준 활동 데이터</MainH2>
            </Pane>
            <Table textAlign="center">
              <Table.Head>
                <Table.TextCell>날짜</Table.TextCell>
                <Table.TextCell>운동 진행 횟수</Table.TextCell>
                <Table.TextCell>활동 바이크 수</Table.TextCell>
              </Table.Head>
              <Table.Body >
                {allBikeActs.map((data, index) => (
                  <Table.Row key={index}>
                    <Table.TextCell>{data.date}</Table.TextCell>
                    <Table.TextCell>{data.activity_count}</Table.TextCell>
                    <Table.TextCell>{data.bike_count}</Table.TextCell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Pane>
        </Pane>

        <Pane paddingLeft={40} paddingRight={40}>
          <Pane textAlign="center">
            <MainH2>시설 출고 바이크 (협찬개인 / 협찬시설 그룹)</MainH2>
          </Pane>
          <Select width={400} onChange={event => setSelectedLocation(event.target.value)}>
            <option disabled selected value> -- select an option -- </option>
            {locations.sort((a, b) => a.group_code - b.group_code).map((data, index) => {
              return (
                <option key={index} value={data.location}>
                  [{data.title}] {data.location}({data.bike_count}대)
                </option>
              )
            })}
          </Select>
        </Pane>
        <Pane width="100%" paddingTop={5} paddingLeft={40} paddingRight={40} paddingBottom={100}>
          <Table textAlign="center">
            <Table.Head>
              <Table.TextCell>날짜</Table.TextCell>
              <Table.TextCell>운동 진행 횟수</Table.TextCell>
              <Table.TextCell>활동 바이크 수</Table.TextCell>
            </Table.Head>
            <Table.Body >
              {bikeActs.map((data, index) => (
                <Table.Row key={index}>
                  <Table.TextCell>{data.date}</Table.TextCell>
                  <Table.TextCell>{data.activity_count}</Table.TextCell>
                  <Table.TextCell>{data.bike_count}</Table.TextCell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Pane>
      </>
    </MainBox>
  );
}

export default Main;

const MainBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const MainH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;

const MainH2 = styled.h2`
  margin: 20px;
  font-size: 1.3vw;
`;
