import { useNavigate } from "react-router";
import { useEffect, useRef, useCallback } from "react";
import axios from "services/auth/jwt/config";
import LocalStorageService from "services/storage/LocalStorageService";

// const baseURL = process.env.REACT_APP_API_URL;

export default function TokenRefresher() {
  const navigate = useNavigate();
  const logout = useCallback(() => {
    LocalStorageService.clearToken();
    navigate("/");
  }, [navigate]); 

  const refreshPromiseRef = useRef(null);

  const clearPromiseRef = () => refreshPromiseRef.current = null;
  // let refreshPromise = null;
  // const clearPromise = () => refreshPromise = null;

  const refreshToken = useCallback(async (originalConfig, msg, status) => {
    const refreshToken = LocalStorageService.getRefreshToken();
    const sessionId = LocalStorageService.getSessionId();
    
    if (msg === 'acceess_token expired' || msg === 'acceess_token invalid') {
      console.log("access_token expired or invalid");
      await axios.post('/admin/auth/tokens', {
        refresh_token: refreshToken,
        session_id: sessionId,
      }).then((res) => {
        console.log(">> res.data: ", res.data);
        LocalStorageService.setToken(res.data);
        originalConfig.headers["Authorization"] = "Bearer " + res.data.access_token;
        // alert("자동으로 토큰을 갱신하였습니다. 다시 시도해주세요.");
        return axios(originalConfig);
      }).catch((error) => {
        logout();
        alert("토큰이 만료되어 자동으로 로그아웃 되었습니다.");
        return;
      }).finally(() => {
        // 화면 리로드
        navigate(0);
      });
    } else {
      logout();
      alert("토큰이 만료되어 자동으로 로그아웃 되었습니다.");
      return;
    }
  }, [navigate, logout]);


  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      function (response) {
        return response;
      },
      async function (error) {
        const originalConfig = error.config;
        const msg = error.response.data.message;
        const status = error.response.status;
        
        if (status === 401 && originalConfig) {

          if (!refreshPromiseRef.current) {
            refreshPromiseRef.current = refreshToken(
              originalConfig, msg, status
            ).finally(clearPromiseRef);
          }
          return await refreshPromiseRef.current;
        } else {
          if (!msg.includes('refresh_token')) {
            alert(msg);
          }
        }
        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [refreshToken]);

  return <></>;
}