import axios from "axios";
import LocalStorageService from "services/storage/LocalStorageService";

const baseURL = process.env.REACT_APP_API_URL;

const localStorageService = LocalStorageService.getService();
const instance = axios.create({
  baseURL,
  headers: { "Content-Type": "application/json" },
});

// let isAlreadyFetchingAccessToken = false;
// let subscribers = [];

instance.interceptors.request.use(
  (config) => {
    // 로그인이나 로그아웃 요청인 경우에는 토큰을 넣지 않는다.
    if (isLogin(config.url) || isLogout(config.url)) {
      return config;
    }

    const accessToken = localStorageService.getAccessToken();
    if (accessToken) {
      config.headers["Authorization"] = "Bearer " + accessToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// instance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     console.log(error);
//     // alert(error.response.data.message);
//     const originalRequest = error.config;
//     console.log(error.response.status);

//     // 로그인 에러가 발생했을 때
//     // if (
//     //   error.response.status === 401
//     // ) {
//     //   return Promise.reject(error)
//     // }

//     // 401 에러가 발생했을 때
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       // console.log("start resetTokenAndReattemptRequest")
//       // console.log(isAlreadyFetchingAccessToken)
//       return await resetTokenAndReattemptRequest(error);
//     }
//     return Promise.reject(error);
//   }
// );

// // TODO: 리액트식 로그아웃 방법으로 변경해야
// const logout = () => {
//   localStorageService.clearToken();
//   console.log("logout");
//   // window.location.href = '/login'
//   // redirect("/")
// };

// async function resetTokenAndReattemptRequest(error) {
//   try {
//     const { response: errorResponse } = error;
//     const refreshToken = localStorageService.getRefreshToken();
//     const sessionId = localStorageService.getSessionId();

//     console.log(errorResponse, refreshToken, sessionId);

//     // refresh 토큰이 없다면 바로 error reject
//     if (!refreshToken) {
//       logout();
//       return Promise.reject(error);
//     }

//     // subscribers에 access token을 받은 이후 재요청할 메소드 추가 (401로 실패했던)
//     // retryOriginalRequest는 pending 상태로 있다가
//     // access token을 받은 이후 onAccessTokenFetched가 호출될 때
//     // access token을 넘겨 다시 axios로 요청하고
//     // 결과값을 처음 요청했던 promise의 resolve로 settle시킨다.
//     const retryOriginalRequest = new Promise((resolve, reject) => {
//       addSubscriber(async (accessToken) => {
//         try {
//           errorResponse.config.headers["Authorization"] =
//             "Bearer " + accessToken;
//           resolve(instance(errorResponse.config));
//         } catch (err) {
//           reject(err);
//         }
//       });
//     });
//     console.log("isAlreadyFetchingAccessToken", isAlreadyFetchingAccessToken);

//     // refresh token을 이용해서 access token 요청
//     if (!isAlreadyFetchingAccessToken) {
//       isAlreadyFetchingAccessToken = true; // 문닫기 (한 번만 요청)
//       const response = await axios.post(process.env.REACT_APP_REFRESH_TOKEN_URL, {
//         refresh_token: refreshToken,
//         session_id: sessionId,
//       });

//       const tokenObj = response.data;

//       console.log(tokenObj);

//       if (!tokenObj) {
//         // accessToken을 못 가져오면 reject하며 로그아웃
//         // TODO: 로그아웃과 초기화 코드 넣어야
//         return Promise.reject(error);
//       }

//       localStorageService.setToken(tokenObj); // access token 저장
//       isAlreadyFetchingAccessToken = false; // 문열기 (초기화)
//       onAccessTokenFetched(tokenObj.accessToken);
//     }

//     return retryOriginalRequest; // pending 됐다가 onAccessTokenFetched가 호출될 때 resolve
//   } catch (err) {
//     // refresh 토큰을 가져오는 과정에서 에러 발생시 로그아웃
//     logout();
//     return Promise.reject(err);
//   }
// }

// function addSubscriber(callback) {
//   subscribers.push(callback);
// }

// function onAccessTokenFetched(accessToken) {
//   subscribers.forEach((callback) => callback(accessToken));
//   subscribers = [];
// }

function isLogin(requestedURL) {
  if (requestedURL === "login/") return true;
  return false;
}

function isLogout(requestedURL) {
  if (requestedURL === "logout/") return true;
  return false;
}

export default instance;
