import React from "react";
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import Header from "components/Header";
import User from "pages/user/User";
import Banner from "pages/home/Banner";
import MobileBanner from "pages/home/MobileBanner";
import { ProSidebarProvider } from "react-pro-sidebar";

import Login from "components/Login";
import PopUp from "components/Popup";
import Instructor from "pages/user/Instructor";
import Group from "pages/user/Group";
import Main from "pages/main/Main";
import Section from "pages/home/Section";
import Music from "pages/resource/Music";
import Tag from "pages/resource/Tag";
import MusicTag from "pages/resource/MusicTag";
import Content from "./pages/studio/LessonData";
import ContentKeyword from "./pages/studio/ContentKeyword";
import LocalStorageService from "services/storage/LocalStorageService";
import Notice from "./pages/service/Notice/index";
import NoticeCategory from "./pages/service/NoticeCategory/index";
import OperationInfo from "./pages/service/OperationInfo/index";
import OperationInfoCategory from "./pages/service/OperationInfoCategory/index";
import Question from "./pages/service/Question/index";
import QuestionCategory from "./pages/service/QuestionCategory/index";
import Challenge from "./pages/contents/RealContents/Challenge/index";
import Bike from "./pages/bike/BikeList/index";
import BikeSchedule from "./pages/bike/BikeSchedule";
import FreeBike from "./pages/bike/FreeBikeList/index";
import Item from "./pages/payment/Item/index";
import LiveContent from "./pages/studio/Live/LiveContent";
import LiveRoom from "./pages/studio/Live/LiveRoom";
import LiveRoomOperation from "./pages/studio/Live/LiveRoomOperation";
import LiveRoomPrompt from "./pages/studio/Live/LiveRoomPrompt";
import Message from "./pages/studio/Live/Message";
import MessageCategory from "./pages/studio/Live/MessageCategory";
import Contract from "./pages/ad/Contract";
import Advertising from "./pages/ad/Advertising";
import ScreenSaver from "./pages/screenSaver";
import InplayAdvertising from "./pages/ad/InplayAdvertising";

import Test from "./pages/Test/index";

import { ThemeProvider, defaultTheme } from "evergreen-ui";
import { merge } from "lodash";
import TokenRefresher from "components/Login/TokenRefresher";

const myTheme = merge({}, defaultTheme, {
  fontFamilies: {
    display: '"Noto Sans KR", sans-serif',
    ui: '"Noto Sans KR", sans-serif',
    mono: '"Noto Sans KR", sans-serif',
  },
});

const localStorageService = LocalStorageService.getService();

function App() {
  // console.log(isLogin);

  return (
    <ProSidebarProvider>
      <ThemeProvider value={myTheme}>
        <div className="App">
          <BrowserRouter>
            <Header />
            <TokenRefresher />
            <Routes>
              <Route element={<AuthLayer />}>
                <Route path="/" element={<Main />} />
                <Route path="/user" element={<User />} />
                <Route path="/group" element={<Group />} />
                <Route path="/instructor" element={<Instructor />} />
                <Route path="/banner" element={<Banner />} />
                <Route path="/popup" element={<PopUp />} />
                <Route path="/banner" element={<Banner />} />
                <Route path="/mobilebanner" element={<MobileBanner />} />
                <Route path="/tag" element={<Tag />} />
                <Route path="/music" element={<Music />} />
                <Route path="/musictag" element={<MusicTag />} />
                <Route path="/section" element={<Section />} />
                <Route path="/content" element={<Content />} />
                <Route path="/contentKeyword" element={<ContentKeyword />} />
                <Route path="/notice" element={<Notice />} />
                <Route path="/noticeCategory" element={<NoticeCategory />} />
                <Route path="/operationInfo" element={<OperationInfo />} />
                <Route
                  path="/operationInfoCategory"
                  element={<OperationInfoCategory />}
                />
                <Route path="/question" element={<Question />} />
                <Route
                  path="/questionCategory"
                  element={<QuestionCategory />}
                />
                <Route path="/challenge" element={<Challenge />} />
                <Route path="/bike" element={<Bike />} />
                <Route path="/bikeSchedule" element={<BikeSchedule />} />
                <Route path="/freeBike" element={<FreeBike />} />
                <Route path="/item" element={<Item />} />

                <Route path="/liveContent" element={<LiveContent />} />
                <Route path="/liveRoom" element={<LiveRoom />} />
                <Route
                  path="/liveRoomOperation"
                  element={<LiveRoomOperation />}
                />
                <Route path="/liveRoomPrompt" element={<LiveRoomPrompt />} />
                <Route path="/message" element={<Message />} />
                <Route path="/messageCategory" element={<MessageCategory />} />

                <Route path="/contract" element={<Contract />} />
                <Route path="/advertising" element={<Advertising />} />
                <Route path="/screenSaver" element={<ScreenSaver />} />
                <Route path="/inplayAdvertising" element={<InplayAdvertising />} />

                <Route path="/test" element={<Test />} />

                <Route path="/*" element={<Navigate to="/" />}></Route>
              </Route>
              <Route element={<RestrictedRoute restricted={true} />}>
                <Route path="/login" element={<Login />} />
              </Route>
              {/* 404 리다이렉트 처리 */}
              {/*<Route path="/*" element={<Navigate to="/login" replace/>}></Route>*/}
            </Routes>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </ProSidebarProvider>
  );
}

const AuthLayer = () => {
  const isLogin = localStorageService.getAccessToken() !== null;
  // console.log(isLogin);
  return isLogin ? <Outlet /> : <Navigate to="/login" replace />;
};

const RestrictedRoute = ({ restricted }) => {
  const isLogin = localStorageService.getAccessToken() !== null;
  return isLogin && restricted ? <Navigate to="/" replace /> : <Outlet />;
};

export default App;
